import { filterDefined } from '@wix/cashier-common/dist/src/utils/filterDefined';
import {
  WidgetClientPropsEnhanced,
  WidgetPriceProps,
} from '../types/public/WidgetClientProps';
import {
  WidgetPlatformProps,
  WidgetViewerPlatformProps,
} from '../types/WidgetPlatformProps';
import { RevalidateAppInstance } from '@wix/cashier-common/dist/src/types/AppInstance';
import { FinalizedBannerProps } from '../types/FinalizedBannerProps';
import { IHostProps } from '@wix/yoshi-flow-editor';

export const buildBannerProps = ({
  platformProps,
  viewerPlatformProps,
  clientProps,
  priceProps,
  host,
  revalidateAppInstance,
}: {
  platformProps: WidgetPlatformProps;
  viewerPlatformProps: WidgetViewerPlatformProps;
  clientProps: WidgetClientPropsEnhanced;
  priceProps: WidgetPriceProps;
  host: IHostProps;
  revalidateAppInstance: RevalidateAppInstance;
}): FinalizedBannerProps => {
  return filterDefined<FinalizedBannerProps>({
    amount: String(priceProps.price),
    currency: priceProps.currency,
    debug: clientProps._debug,

    locale: viewerPlatformProps.locale,
    deviceType: viewerPlatformProps.isMobile ? 'mobile' : 'desktop',
    isSSR: viewerPlatformProps.isSSR,
    delayedCapture: clientProps.delayedCapture,

    meta: filterDefined<FinalizedBannerProps['meta']>({
      appDefId: clientProps.appDefId,
      appInstanceId: clientProps.appInstanceId,
      productId: clientProps.productId,
      host: clientProps._baseUrlOverride,

      appInstance: platformProps.appInstance,
      msid: platformProps.msid,
      siteOwnerId: platformProps.siteOwnerId,
      visitorId: platformProps.visitorId || undefined, // account for null value
    }),

    LazySentry: host.LazySentry,
    openModal: (url: string, width: number, height: number) =>
      platformProps.openModal(url, { width, height }),
    revalidateAppInstance,
  });
};
