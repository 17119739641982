import { useMemo } from 'react';
import { useAppInstanceRevalidation } from './useAppInstanceRevalidation';
import { buildBannerProps } from '../utils/buildBannerProps';
import { FinalizedBannerProps } from '../types/FinalizedBannerProps';
import { WidgetRootProps } from '../types/WidgetRootProps';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useBannerTheme } from './useBannerTheme';
import { useTextAlign } from './useTextAlign';
import { wiredComponents } from '../components/PaymentMethodsBannerWidget/Widget/wired-components';

export const useBannerProps = ({
  platformProps,
  clientProps,
  priceProps,
  host,
}: WidgetRootProps): FinalizedBannerProps => {
  const env = useEnvironment();
  const theme = useBannerTheme();
  const textAlign = useTextAlign();
  const revalidateAppInstance = useAppInstanceRevalidation(platformProps);

  return useMemo(
    () => ({
      ...buildBannerProps({
        platformProps,
        viewerPlatformProps: {
          locale: env.language,
          isMobile: env.isMobile,
          isSSR: env.isSSR,
        },
        clientProps,
        priceProps,
        host,
        revalidateAppInstance,
      }),
      theme,
      textAlign,
      wiredComponents,
    }),
    [
      clientProps,
      platformProps,
      priceProps,
      host,
      theme,
      textAlign,
      env,
      revalidateAppInstance,
    ],
  );
};
